import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar, throwToolbarMixedModesError } from '@angular/material';
import { CompaniesService, UserService, FirebaseService, LocationsService, TimetableService, Company } from '../shared';
import { map } from 'rxjs/operators';
import { AngularFireStorage } from '@angular/fire/storage';
import { environment } from '../../environments/environment';

@Component({
  selector: 'screen-dialog',
  templateUrl: 'screen-dialog.component.html',
  styleUrls: ['./screen-dialog.component.scss']
})
export class ScreenDialog implements OnInit, OnDestroy {

  saving = false;
  resetting = false;
  newConnectionMethod = false;
  availableScreens = null;
  screens = null;
  screenExists = false;
  authKey = null;
  screenToUpdate = null;
  screenUUId = null;
  screenDocId = null;
  fireStoreScreen$: any;
  validCode = false;
  wrongCode = false;
  locations = [];
  locationId: any;
  uuidFieldDisabled = false;
  captureUrl: string;
  qrSize: number;
  environment: any;
  step = 1;
  companyId = null;

  companySubscription: any;
  company: Company;
  companiesSubscription: any;
  companies: Company[];

  constructor(
    public dialogRef: MatDialogRef<ScreenDialog>,
    public companiesService: CompaniesService,
    public userService: UserService,
    public snackBar: MatSnackBar,
    public firebaseService: FirebaseService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private locationsService: LocationsService,
    private timetableService: TimetableService,
    private storage: AngularFireStorage
  ) {
  }

  ngOnInit() {
    let companyId = null;
    try {
      companyId = JSON.parse(localStorage.getItem('currentUser')).companyId;
      if (companyId) {
        this.companyId = companyId;
        // Load all locations of user
        this.locationsService.getAllLocationsWithScreens(companyId).subscribe(() => {}); 
      }
    } catch {
      // nothing
    }
    this.environment = environment;
    this.qrSize = 180;
    if (!this.data.screen.orientation) {
      this.data.screen.orientation = 'landscape';
    }
    if (this.data.screen.orientation === 'landscape') {
      this.data.screen.rotation = '0';
      if (this.data.screen.isInverted) {
        this.data.screen.rotation = '180';
      }
    }
    if (this.data.screen.orientation === 'portrait') {
      this.data.screen.rotation = '90';
      if (this.data.screen.isInverted) {
        this.data.screen.rotation = '270';
      }
    }
    if (this.data.screen.locationId) {
      this.onCompanyChange();
    }
    if (!this.data.screen.screenId) {
      this.data.screen.connectionMethod = true;
    }
    if (this.data.screen.firestoreUUID) {
      this.getScreenCaptureUrl(this.data.screen.firestoreUUID);
      this.uuidFieldDisabled = true;
      this.firebaseService.readScreens(this.data.screen.firestoreUUID).subscribe(
        (data) => {
          if (data.fields.rotation) {
            this.data.screen.rotation = data.fields.rotation.stringValue;
          }
          if (!this.data.screen.rotation) {
            this.data.screen.rotation = '0';
          }
        }, (err) => {
          console.log(err);
        }
      );
    }
    if (!this.data.screen.rotation) {
      this.data.screen.rotation = '0';
    }
    if (!this.data.screen.isInverted) {
      this.data.screen.isInverted = false;
    }
    this.validCode = false;
    this.wrongCode = false;

    const c = localStorage.getItem('connectScreen');
    if (c) {
      this.data.screen.firestoreUUID = JSON.parse(c).fields.uuid.stringValue;
      this.formatFireBaseScreenCode();
    }
    this.locations = this.locationsService.allLocationsSubject.value;
    this.locationsService.allLocations.subscribe(
      value => {
        console.log("locs", value)
        this.locations = value;
      }
    );
    if (this.locations.length < 1) {
      this.locationsService.getAllLocations().subscribe(() => { });
    }

    // subscribe to active company and companies (for select)
    this.companiesSubscription = this.companiesService.allCompanies.subscribe(
      (companies) => {
        this.companies = companies;
      }
    );
    this.companySubscription = this.companiesService.company.subscribe(
      value => {
        console.log("active company", value)
        this.company = value;
      }
    );
    // get all companies
    this.companiesService.getAllCompanies().subscribe((res) => {});
  }

  ngOnDestroy() {
    this.validCode = false;
    this.wrongCode = false;
    if (this.companiesSubscription) { this.companiesSubscription.unsubscribe(); }
    if (this.companySubscription) { this.companySubscription.unsubscribe(); }
  }

  onCompanyChange() {
    this.locations = null;
    this.locationsService.getAllLocationsWithScreens(this.data.screen.companyId).subscribe(() => {});
  }

  onConfirm() {
    this.dialogRef.close(this.data);
  }

  onCancel(): void {
    if (localStorage.getItem('connectScreen')) {
      localStorage.removeItem('connectScreen');
    }
    this.dialogRef.close();
  }

  getScreenCaptureUrl(uuid) {

    const ref = this.storage.ref('screen-captures/' + uuid);
    ref.getDownloadURL().subscribe(res => this.captureUrl = res);

  }

  onSave() {
    this.wrongCode = false;
    if (this.data.screen.rotation === '0') { this.data.screen.orientation = 'landscape'; this.data.screen.isInverted = false; }
    if (this.data.screen.rotation === '90') { this.data.screen.orientation = 'portrait'; this.data.screen.isInverted = false; }
    if (this.data.screen.rotation === '180') { this.data.screen.orientation = 'landscape'; this.data.screen.isInverted = true; }
    if (this.data.screen.rotation === '270') { this.data.screen.orientation = 'portrait'; this.data.screen.isInverted = true; }
    if (!this.data.screen.screenName) { return; }
    console.log("ON SAVE THIS SCREEN YO: " + this.data.screen)
    if (this.data.screen.firestoreUUID) {
      if (this.data.screen.firestoreUUID.length > 0 && this.data.screen.firestoreUUID.length < 14) {
        this.wrongCode = true;
        return;
      }
      if (this.data.screen.firestoreUUID.length === 14) {
        this.checkForExistingScreens();
        if (this.wrongCode) {
          return;
        } else {
          this.dialogRef.close();
        }
      } else {
        if (this.data.screen.screenId) {
          this.updateScreen();
        } else {
          this.createScreen();
        }
      }
    } else {
      if (this.data.screen.screenId) {
        this.updateScreen();
      } else {
        this.createScreen();
      }
    }
  }

  createScreen() {
    this.saving = true;
    const screen = {
      'locationId': this.data.screen.locationId,
      'screenName': this.data.screen.screenName,
      'orientation': this.data.screen.orientation,
      'screenNr': this.data.screen.screenNr,
      'isInverted': this.data.screen.isInverted
    };
    this.companiesService.createScreen(screen)
      .subscribe(
        data => {
          this.dialogRef.close(data);
        },
        () => {
          this.saving = false;
        }
      );
  }

  updateScreen() {
    this.saving = true;
    const screen = {
      'screenId': this.data.screen.screenId,
      'locationId': this.data.screen.locationId,
      'screenName': this.data.screen.screenName,
      'orientation': this.data.screen.orientation,
      'screenNr': this.data.screen.screenNr,
      'isInverted': this.data.screen.isInverted,
      'firestoreUUID': this.data.screen.firestoreUUID,
    };
    this.companiesService.updateScreen(screen)
      .subscribe(
        data => {
          this.dialogRef.close(this.data);
        },
        () => {
          this.saving = false;
        }
      );
  }

  onResetScreen() {
    this.resetting = true;
    this.companiesService.resetScreen(this.data.screen.screenId)
      .subscribe(
        data => {
          this.resetting = false;
          this.snackBar.open('Screen wurde neugestartet', undefined, {
            duration: 3000,
            horizontalPosition: 'start'
          });
        },
        error => {
          this.resetting = false;
        }
      );
  }

  onLocationChange() {
    const location = this.locations.filter((l) => l.locationId === this.data.screen.locationId)[0];
    this.locationsService.setActiveLocation(location, this.locations.indexOf(location));
  }

  checkForExistingScreens() {
    this.screenExists = false;
    if (this.fireStoreScreen$) { return; }
    this.screenDocId = this.data.screen.firestoreUUID;
    this.screenToUpdate = this.firebaseService.readScreens(this.data.screen.firestoreUUID)
      .subscribe(
        data => {
          if (data) {
            console.log("read screens result: ", data);
            if (data.fields.authKey) {  
              if (!this.data.screen.screenId) {
                // user trying to create this screen but screen exists already
                this.screenExists = true;
              } else {
                this.updateAzureFirestoreUUID();
              }
            } else {
              // Screen exists in firestore but has no authKey from Azure
              // Make a new Azure Screen and save the authKey to firestore
              this.getAuthKey();
              this.uuidFieldDisabled = true;
            }
            this.wrongCode = false;
          }
        },
        err => {
          if (err) {
            this.wrongCode = true;
          }
        }
      );
  }

  updateAzureFirestoreUUID() {
    this.companiesService.updateScreen(this.data.screen).subscribe(
      (data) => {
        const screenData = {
          'authKey': data.authKey,
          'companyId': data.companyId,
          'locationId': this.locationId ? this.locationId : data.locationId,
          'screenId': data.screenId,
          'rotation': this.data.screen.rotation
        };
        this.firebaseService.updateConnectedScreen(this.data.screen.firestoreUUID, screenData);

      }, (err) => {
        console.log(err);
      }
    );
  }

  getAuthKey() {
    const screen = {
      'locationId': this.locationId ? this.locationId : this.data.screen.locationId,
      'orientation': 'landscape',
      'screenName': 'Screen-' + this.screenDocId,
      'firestoreUUID': this.screenDocId
    };
    this.companiesService.createScreen(screen).subscribe(
      data => {
        const screenData = {
          'authKey': data.authKey,
          'companyId': data.companyId,
          'locationId': this.locationId ? this.locationId : data.locationId,
          'screenId': data.screenId,
          'rotation': this.data.screen.rotation,
        };

        this.authKey = data.authKey;
        this.data.screen.screenId = data.screenId;
        this.data.screen.authKey = this.authKey;
        this.data.screen.firestoreUUID = data.firestoreUUID;
        this.data.screen.screenName = 'Screen-' + this.screenDocId;
        localStorage.removeItem('connectScreen');
        this.timetableService.activeScreen.next(this.data.screen);
        this.timetableService.activeScreenId.next(this.data.screen.screenId);
        this.firebaseService.updateScreenAuthKey(this.screenDocId, screenData);

        this.dialogRef.close(data);
        this.snackBar.open("Screen erfolgreich verbunden", null, { duration: 2500 })
      },
      (error) => {

        this.saving = false;
      }
    );
  }


  formatFireBaseScreenCode() {
    const code = this.data.screen.firestoreUUID;
    this.wrongCode = false;
    if (!code) {
      return;
    }
    if (code.length === 4 || code.length === 9) {
      const formatted = code.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1-').trim();
      this.data.screen.firestoreUUID = formatted;
    }
    if (code.length === 14) {
      this.validCode = true;
    } else {
      this.validCode = false;
    }
  }

  disableNext() {
    let disable = true;
    if (this.validCode && this.data.screen.locationId) {
      disable = false;
    }
    if (this.validCode && this.locationId) {
      disable = false;
    }
    return disable;
  }

  copyUUIDToClipboard() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.data.screen.firestoreUUID;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.snackBar.open('UUID Code erfolgreich in Zwischenablage kopiert', undefined, {
      duration: 3000,
      horizontalPosition: 'center'
    });
  }



}
