export const environment = {
    production: true,
    identifier: 'staging',
    languages: ['en', 'de', 'fr', 'it', 'es'],
    api_url: 'https://screenlime.azurewebsites.net',
    azureCDN: 'https://media.screenlime.ch/',
    ffmpeg_url: 'https://ffmpegpublisher.inaffect.one/prod',
    firebase: {
      apiKey: 'AIzaSyBTni4gEXSjY-grotEtqRqzPqWCUxd5pLg',
      authDomain: 'screenlime.firebaseapp.com',
      databaseURL: 'https://screenlime.firebaseio.com',
      projectId: 'screenlime',
      storageBucket: 'screenlime.appspot.com',
      messagingSenderId: '552745022623',
      appId: '1:552745022623:web:4a3c94e425114256371eaa',
      measurementId: 'G-GXNPB9SBYL'
    },
    firestore_api_url: 'https://firestore.googleapis.com/v1/projects/screenlime/databases/(default)/documents/',
    firefunction_api_url: 'https://us-central1-screenlime.cloudfunctions.net/api/',
    player: {
      baseUrl: 'https://player-staging.screenlime.app/',
      connectPath: 'connect/',
    },
};
