
import {filter} from 'rxjs/operators';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Company, CompaniesService, LocationsService, TimetableService } from '../shared';
import * as _ from 'lodash';
import { PerfectScrollbarComponent, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { Router, NavigationStart, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material';
import { SpecialDateDialog } from '../dialogs/special-date-dialog.component';
import { LocationDialogComponent } from '../dialogs/location-dialog.component';
import { ScreenDialog } from '../dialogs/screen-dialog.component';
import {appLog} from '../shared/utils/app-log.util';
import { Location } from '../shared/models/location.model';
import * as moment from 'moment';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit, OnDestroy {
  company: Company = new Company();
  loading = {
    locations: false,
    overview: true
  };
  allLocations = [];
  halfHourBlocks = [];
  horizScrollConf = {
    wheelPropagation: true,
    swipePropagation: true,
    alwaysVisible: true,
    suppressScrollY: true,
    handlers: ['click-rail', 'drag-thumb', 'keyboard', 'wheel', 'touch']
  };
  vertScrollConf = {
    wheelPropagation: true,
    swipePropagation: true,
    alwaysVisible: true,
    suppressScrollX: true
  };
  timetableHeaderTopPosition = 0;
  days = [];
  scrollRightDisabled = false;
  noModules = true;
  activeLocation: any;
  activeLocationIndex = 0;
  currentRoute = undefined;
  initialTimeTableBuild = false;
  lastTimeTablesBuild: Date;

  // subscriptions
  companySubscription: any;
  activeLocationSubscription: any;
  activeLocationIndexSubscription: any;
  allLocationsSubscription: any;
  routerSubscription: any;

  // active Timeline (mobile)
  // on default "Standard"-Timeline is selected
  activeTimeline = {
    index: 0,
    timeTableType: 'Main',
    timeTableValue: null
  };

  @ViewChild(PerfectScrollbarComponent, {static: false}) componentScroll: PerfectScrollbarComponent;
  @ViewChild(PerfectScrollbarDirective, {static: false}) directiveScroll: PerfectScrollbarDirective;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public companiesService: CompaniesService,
    public locationsService: LocationsService,
    public timetableService: TimetableService,
    public dialog: MatDialog,
  ) {
    this.days = this.timetableService.days;
    // subscribe to the active company
    this.companySubscription = this.companiesService.company.subscribe(
      value => {
        // get all Locations from this company, if the company has changed
        if (JSON.stringify(this.company) !== JSON.stringify(value)) {
          this.company = value;
          if (this.company && this.company.companyId) {
            this.getAllLocations(this.company.companyId);
          } 
        }
      }
    );
    // subscribe to the active Location
    this.activeLocationSubscription = this.locationsService.activeLocation.subscribe(
      value => {
        const locationId = this.route.snapshot.paramMap.get("locationId")
       if (value) {
        this.activeLocation = value;
        //this.scrollToLocation();
        if (this.allLocations) {
          if (this.allLocations.length > 0) {
              setTimeout(() => {
                this.buildTimeTables();
              }, 250);
          }
        }
       }
      }
    );
    this.activeLocationIndexSubscription = this.locationsService.activeLocationIndex.subscribe(
      value => {
        this.activeLocationIndex = value;
      }
    );
    this.allLocationsSubscription = this.locationsService.allLocations.subscribe(
      value => {
        if (this.initialTimeTableBuild === true) { return; }
        if (JSON.stringify(this.allLocations) !== JSON.stringify(value)) {
          this.allLocations = value;
          setTimeout(() => {
            this.buildTimeTables();
          }, 250);
        }
      }
    );
  }

  ngOnInit() {
    
  }

  ngOnDestroy() {
    // Destroy subscriptions
    this.companySubscription.unsubscribe();
    this.activeLocationSubscription.unsubscribe();
    this.activeLocationIndexSubscription.unsubscribe();
    this.allLocationsSubscription.unsubscribe();
  }

  alphabeticallySorted(screens) {
    return screens.sort((val1, val2) => {
       if (val1.screenName.toLowerCase() < val2.screenName.toLowerCase()) { return -1; }
       if (val1.screenName.toLowerCase() > val2.screenName.toLowerCase()) { return 1; }
       return 0;
     });
   }

  toggleLocation(location: Location, index) {
    const loc = _.cloneDeep(location);
    this.locationsService.setActiveLocation(loc, index);
    this.router.navigateByUrl('overview/' + this.companiesService.company.value.companyId + '/' + loc.locationId);
  }

  openLocationDialog(location): void {
    const dialogRef = this.dialog.open(LocationDialogComponent, {
      width: '420px',
      disableClose: false,
      data: {
        location: location,
        company: this.companiesService.company.value
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      appLog(result);
    });
  }

  calcLayoutMarginTop() {
    return window.innerWidth > 1099 ? this.activeLocationIndex * 48 : 0;
  }

  isMobile() {
    // return window.innerWidth < 1100;
    return true;
  }

  getPlayWidth(sizeX) {
    if (!this.isMobile()) {
      return sizeX * 5 + 'px';
    } else {
      return (window.innerWidth / 48) * sizeX + 'px';
    }
  }

  onAddScreen(screen) {
    if (screen) {
      const compId = this.companiesService.company.value.companyId;
      const locId = this.locationsService.activeLocation.value.locationId;
      this.router.navigateByUrl('timeline/' + compId + '/' + locId + '/' + screen.screenId);
    }
  }

  switchToEditScreen(screen, mode) {
    const compId = this.companiesService.company.value.companyId;
    const locId = this.activeLocation.locationId;
    this.router.navigateByUrl('timeline/' + compId + '/' + locId + '/' + screen.screenId + '/' + mode);
  }

  scrollToLocation() {
    const locationElement = document.getElementById('location-' + this.activeLocation.locationId);
    if (this.directiveScroll) {
      this.directiveScroll.scrollToY((this.activeLocationIndex + 1) * 48, 0.1);
    }
  }

  isScrollableToRight() {
    if (this.componentScroll) {
      const currentPosition = this.componentScroll.directiveRef.geometry().x;
      return true;
    }
  }
  isScrollableToLeft() {
    if (this.componentScroll) {
      const currentPosition = this.componentScroll.directiveRef.geometry().x;
      return currentPosition > 0 ? true : false;
    }
  }

  onXReachEnd(event) {
    this.scrollRightDisabled = true;
  }

  onScrollLeft() {
    this.scrollRightDisabled = false;
  }

  scrollToRight() {
    if (!this.isMobile()) {
      const currentPosition = this.componentScroll.directiveRef.geometry().x;
      this.componentScroll.directiveRef.scrollToX(currentPosition + 240);
    } else {
      const nextIndex = this.activeTimeline.index + 1;
      const nextTimeline = this.timetableService.days[nextIndex];
      this.activeTimeline.index = nextIndex;
      this.activeTimeline.timeTableType = this.timetableService.days[nextIndex].timeTableType;
      this.activeTimeline.timeTableValue = this.timetableService.days[nextIndex].timeTableValue;
      this.buildTimeTables();
    }
  }
  scrollToLeft() {
    if (!this.isMobile()) {
      const currentPosition = this.componentScroll.directiveRef.geometry().x;
      this.componentScroll.directiveRef.scrollToX(currentPosition - 240);
    } else {
      const nextIndex = this.activeTimeline.index - 1;
      if (nextIndex >= 0) {
        const nextTimeline = this.timetableService.days[nextIndex];
        this.activeTimeline.index = nextIndex;
        this.activeTimeline.timeTableType = this.timetableService.days[nextIndex].timeTableType;
        this.activeTimeline.timeTableValue = this.timetableService.days[nextIndex].timeTableValue;
      } else {
        this.activeTimeline.index = -1;
        this.activeTimeline.timeTableType = 'Module';
        this.activeTimeline.timeTableValue = null;
      }

      this.buildTimeTables();
    }
  }

  previousTimelineName() {
    if (this.activeTimeline.index === -1) {
      return null;
    }
    const prevIndex = this.activeTimeline.index - 1;
    if (prevIndex === -1) {
      return 'Module';
    } else {
      return this.timetableService.days[prevIndex].label;
    }
  }

  nextTimelineName() {
    if (this.activeTimeline.index === 7) {
      return null;
    }
    const nextIndex = this.activeTimeline.index + 1;
    if (nextIndex === -1) {
      return 'Module';
    } else {
      return this.timetableService.days[nextIndex].label;
    }
  }

  onScrollY(event) {
    if (this.isMobile()) {
      this.timetableHeaderTopPosition = 88 + event.target.scrollTop;
    }
    this.timetableHeaderTopPosition = event.target.scrollTop;
  }

  // Gets all Locations with Screens of a Company
  getAllLocations(companyId) {
    this.loading.locations = true;
    this.locationsService.getAllLocationsWithScreens(companyId)
    .subscribe(
      data => {
        this.loading.locations = false;
        this.allLocations = data;
        if (this.allLocations) {
          if (this.allLocations.length) {
            const locationId = this.route.snapshot.paramMap.get("locationId")
            if (locationId) {
              this.activeLocation = data.find((d) => d.locationId === locationId)
            } else {
              this.activeLocation = data[0];
            }
            this.locationsService.activeLocation.next(this.activeLocation);
            this.buildHalfHourBlocks();
          }
        } else {
          this.loading.overview = false;
        }
      },
      err => {
        this.loading.locations = false;
        this.loading.overview = false;
      }
    );
  }

  trackByFn(index, item) {
    return index;
  }

  // Builds an Array for every half hour in a day
  buildHalfHourBlocks() {
    this.initialTimeTableBuild = true;
    if (this.halfHourBlocks.length !== 48) {
      const halfHourBlocks = [];
      for (let i = 0; i < 48; i++) {
        const block = {
          hour: 0.5 * i
        };
        halfHourBlocks.push(block);
      }
      this.halfHourBlocks = _.cloneDeep(halfHourBlocks);
      // this.buildTimeTables();
    } else {
      this.buildTimeTables();
    }
  }

  // Copies the previously generated Half-Hour-Blocks Array to the screen timelines
  copyHalfHourBlocksToTimelines(locationIndex, screenIndex) {
    const i = locationIndex;
    const screen = screenIndex;
    // Copy Half Hour Blocks to module (-1), standard (0), mo-sun (1-7)
    if (!this.isMobile() || this.activeTimeline.index === -1) {
      this.allLocations[i].screens[screen].module = _.cloneDeep(this.halfHourBlocks);
    }
    if (!this.isMobile() || this.activeTimeline.index === 0) {
      this.allLocations[i].screens[screen].standard = _.cloneDeep(this.halfHourBlocks);
    }
    if (!this.isMobile() || this.activeTimeline.index === 1) {
      this.allLocations[i].screens[screen].monday = _.cloneDeep(this.halfHourBlocks);
    }
    if (!this.isMobile() || this.activeTimeline.index === 2) {
      this.allLocations[i].screens[screen].tuesday = _.cloneDeep(this.halfHourBlocks);
    }
    if (!this.isMobile() || this.activeTimeline.index === 3) {
      this.allLocations[i].screens[screen].wednesday = _.cloneDeep(this.halfHourBlocks);
    }
    if (!this.isMobile() || this.activeTimeline.index === 4) {
      this.allLocations[i].screens[screen].thursday = _.cloneDeep(this.halfHourBlocks);
    }
    if (!this.isMobile() || this.activeTimeline.index === 5) {
      this.allLocations[i].screens[screen].friday = _.cloneDeep(this.halfHourBlocks);
    }
    if (!this.isMobile() || this.activeTimeline.index === 6) {
      this.allLocations[i].screens[screen].saturday = _.cloneDeep(this.halfHourBlocks);
    }
    if (!this.isMobile() || this.activeTimeline.index === 7) {
      this.allLocations[i].screens[screen].sunday = _.cloneDeep(this.halfHourBlocks);
    }
  }

  buildTimeTables() {

    // cancel if timetable was built within the last 200ms
    appLog(this.lastTimeTablesBuild);
    if (this.lastTimeTablesBuild) {
      const now = new Date();
      const diff = now.getTime() - this.lastTimeTablesBuild.getTime();
      appLog(diff);
      if (diff < 201) {
        appLog('return');
        return;
      }
    }
    this.lastTimeTablesBuild = new Date();
    this.loading.overview = true;
    const locLength = this.allLocations.length;
    let i; // index of active Location

    if (this.activeLocation.locationId) {
      this.allLocations.forEach((location, index) => {
        if (location.locationId === this.activeLocation.locationId) {
          i = index;
        }
      });
    } else if (!this.company.companyId || !this.allLocations.length) {
      if (!this.allLocations.length) {
        this.getAllLocations(this.company.companyId);
      }
      this.loading.overview = false;
      return;
    } else {
      this.loading.overview = false;
      return;
    }
    // How many screens are there on the active location (screensLenght)
    const screensLength = this.allLocations[i] ? this.allLocations[i].screens.length : 0;
    if (screensLength < 1) {
      this.loading.overview = false;
      return;
    }
    for (let screen = 0; screen < screensLength; screen++) {
      // unique id for entries per screen
      let entryId = 0;
      // Copy Half Hour Blocks to module (-1), standard (0), mo-sun (1-7) timetables in overview
      this.copyHalfHourBlocksToTimelines(i, screen);
      // get used dates (special timetable entries)
      this.timetableService.getUsedDates(this.allLocations[i].screens[screen].screenId)
      .subscribe(
        data => {
          const future = [];
          data.dates.forEach((d) => {
            if (moment(d).isAfter(moment().subtract(1, 'days'))) {
              future.push(d);
            }
          })
          this.allLocations[i].screens[screen].usedDates = future;
        }
      );
      const screenId = this.allLocations[i].screens[screen].screenId;
      // get timetable entries for Module-Timetable
      if (!this.isMobile() || this.activeTimeline.index === -1) {
        this.timetableService.getModuleTimetableForOverview(screenId)
          .subscribe(
            data => {
              if (data) {
                this.noModules = false;
                for (let d = 0; d < data.length; d++) {
                  const obj = this.buildTimeTableDataObject(data[d]);
                  const tmp = _.cloneDeep(entryId);
                  entryId += 1;
                  obj.entryId = tmp;
                  this.allLocations[i].screens[screen].module[obj.halfHourIndex] = _.cloneDeep(obj);
                }
              }
            }
          );
      }

      // get timetable entries for standard (index 0) and monday-sunday (1-7)
      for (let dayIndex = 0; dayIndex < 8; dayIndex++) {
        if (!this.isMobile() || this.activeTimeline.index === dayIndex) {
          this.timetableService.getTimetableWithIndex(screenId, dayIndex)
            .subscribe(
              data => {
                switch (dayIndex) {
                  case 0: {
                    if (data) {
                      for (let d = 0; d < data.length; d++) {
                        const obj = this.buildTimeTableDataObject(data[d]);
                        const tmp = _.cloneDeep(entryId);
                        entryId += 1;
                        obj.entryId = tmp;
                        this.allLocations[i].screens[screen].standard[obj.halfHourIndex] = _.cloneDeep(obj);
                      }
                    }
                    break;
                  }
                  case 1: {
                    if (data) {
                      for (let d = 0; d < data.length; d++) {
                        const obj = this.buildTimeTableDataObject(data[d]);
                        const tmp = _.cloneDeep(entryId);
                        entryId += 1;
                        obj.entryId = tmp;
                        this.allLocations[i].screens[screen].monday[obj.halfHourIndex] = _.cloneDeep(obj);
                      }
                    }
                    break;
                  }
                  case 2: {
                    if (data) {
                      for (let d = 0; d < data.length; d++) {
                        const obj = this.buildTimeTableDataObject(data[d]);
                        const tmp = _.cloneDeep(entryId);
                        entryId += 1;
                        obj.entryId = tmp;
                        this.allLocations[i].screens[screen].tuesday[obj.halfHourIndex] = _.cloneDeep(obj);
                      }
                    }
                    break;
                  }
                  case 3: {
                    if (data) {
                      for (let d = 0; d < data.length; d++) {
                        const obj = this.buildTimeTableDataObject(data[d]);
                        const tmp = _.cloneDeep(entryId);
                        entryId += 1;
                        obj.entryId = tmp;
                        this.allLocations[i].screens[screen].wednesday[obj.halfHourIndex] = _.cloneDeep(obj);
                      }
                    }
                    break;
                  }
                  case 4: {
                    if (data) {
                      for (let d = 0; d < data.length; d++) {
                        const obj = this.buildTimeTableDataObject(data[d]);
                        const tmp = _.cloneDeep(entryId);
                        entryId += 1;
                        obj.entryId = tmp;
                        this.allLocations[i].screens[screen].thursday[obj.halfHourIndex] = _.cloneDeep(obj);
                      }
                    }
                    break;
                  }
                  case 5: {
                    if (data) {
                      for (let d = 0; d < data.length; d++) {
                        const obj = this.buildTimeTableDataObject(data[d]);
                        const tmp = _.cloneDeep(entryId);
                        entryId += 1;
                        obj.entryId = tmp;
                        this.allLocations[i].screens[screen].friday[obj.halfHourIndex] = _.cloneDeep(obj);
                      }
                    }
                    break;
                  }
                  case 6: {
                    if (data) {
                      for (let d = 0; d < data.length; d++) {
                        const obj = this.buildTimeTableDataObject(data[d]);
                        const tmp = _.cloneDeep(entryId);
                        entryId += 1;
                        obj.entryId = tmp;
                        this.allLocations[i].screens[screen].saturday[obj.halfHourIndex] = _.cloneDeep(obj);
                      }
                    }
                    break;
                  }
                  case 7: {
                    if (data) {
                      for (let d = 0; d < data.length; d++) {
                        const obj = this.buildTimeTableDataObject(data[d]);
                        const tmp = _.cloneDeep(entryId);
                        entryId += 1;
                        obj.entryId = tmp;
                        this.allLocations[i].screens[screen].sunday[obj.halfHourIndex] = _.cloneDeep(obj);
                      }
                    }
                    break;
                  }
                }
              }
            );
        }
      }
      if (screen === (screensLength - 1)) {
        setTimeout(() => {
          this.loading.overview = false;
        }, 100);
      }
    }
    this.initialTimeTableBuild = false;
  }



  buildTimeTableDataObject(data) {
    const obj = {
      startTime: undefined,
      startHour: undefined,
      startMinutes: undefined,
      halfHourIndex: undefined,
      endTime: undefined,
      endHour: undefined,
      endMinutes: undefined,
      sizeX: undefined,
      timeTableId: undefined,
      timeTableType: undefined,
      timeTableValue: undefined,
      screenId: undefined,
      mediaId: undefined,
      media: undefined,
      hour: undefined,
      entryId: undefined
    };
    obj.startTime = new Date(data.startTime);
    obj.startHour = obj.startTime.getUTCHours();
    obj.startMinutes = obj.startTime.getUTCMinutes();
    obj.halfHourIndex = obj.startMinutes === 0 ? obj.startHour * 2 : obj.startHour * 2 + 1;
    obj.endTime = new Date(data.endTime);
    obj.endHour = obj.endTime.getUTCHours();
    obj.endMinutes = obj.endTime.getUTCMinutes();
    obj.sizeX = ((((obj.endHour > 0 || obj.endMinutes > 0) ? (obj.endHour * 2) : 48 )) + (obj.endMinutes > 0 ? 1 : 0)) - ((obj.startHour * 2) + (obj.startMinutes > 0 ? 1 : 0));
    obj.timeTableId = data.timeTableId;
    obj.timeTableType = data.timeTableType;
    obj.timeTableValue = data.timeTableValue;
    obj.screenId = data.screenId;
    obj.mediaId = data.mediaId;
    obj.media = _.cloneDeep(data.media);
    obj.hour = obj.startMinutes === 0 ? obj.startHour : obj.startHour + 0.5;
    // appLog(obj.screenId, obj.sizeX, obj.timeTableType, obj.timeTableValue, data.startTime, obj.startTime, obj.startHour, obj.startMinutes, ' - ' , data.endTime, obj.endTime, obj.endHour, obj.endMinutes);
    return obj;
  }

  openScreenDialog(screen, locationId, event): void {
    let isNewScreen = false;
    if (!screen.screenId) {
      isNewScreen = true;
    }
    event.stopPropagation();
    screen.locationId = locationId;
    const dialogRef = this.dialog.open(ScreenDialog, {
      width: '420px',
      disableClose: false,
      data: {
        screen: screen
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (isNewScreen) {
        appLog('add screen', result);
        this.onAddScreen(result);
      } else {
        appLog('change screen');
        // this.onChangeScreen(result.screenId);
      }
    },
      err => {
        // this.getAllLocations(this.company.companyId);
      });
  }

  openSpecialDateDialog(screenId, usedDates): void {
    const dialogRef = this.dialog.open(SpecialDateDialog, {
      width: '300px',
      disableClose: false,
      data: {
        activeScreenId: screenId,
        usedDates: usedDates,
        viewMode: true
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.selectedDate) {
          const compId = this.companiesService.company.value.companyId;
          const locId = this.locationsService.activeLocation.value.locationId;
          this.router.navigateByUrl('timeline/' + compId + '/' + locId + '/' + screenId + '/' + 'specialDate/' + result.selectedDate);
        } else if (result.selectedDates) {
          const compId = this.companiesService.company.value.companyId;
          const locId = this.locationsService.activeLocation.value.locationId;
          this.router.navigateByUrl('timeline/' + compId + '/' + locId + '/' + screenId + '/' + 'specialDate/' + result.selectedDates[0].format("YYYY-MM-DD"));
        }
      }
    });
  }

  currentYear() {
    return (new Date()).getFullYear();
  }
}
